import React, { Component } from 'react'
import './terms.css'

export default class TermsComponent  extends Component {
  render() {
    return (
      <div className='container'>
        <h2 className="term-title">Disclaimer </h2>
        <p className='terms-content' style={{display: this.props.agree}}>
        By proceeding further and clicking on the “Agree” the user acknowledges that: 
        </p>
        <p className="terms-content">
            The content of this website is for informational purposes only and should not be interpreted as soliciting or advertisement. Material/information published on this website is purely for the purpose of providing knowledge to the viewers of this website. No material/information provided on this website should be construed as legal advice. Anu Varghese  ,In any manner whatsoever should not be held liable for consequences of any action taken by relying on the material/information provided on this website.
        </p>
        <p className="terms-content">
            The rules of the Bar council of India prohibit the advertisement or solicitation by advocates in any form or manner. By clicking on “I Agree” the user acknowledges and confirm that you are seeking information relating to Anu Varghese  of your own free will and that there has been no form of solicitation, advertisement or inducement by Anu Varghese  in any manner whatsoever.
        </p>
        <p className="terms-content"  style={{marginBottom: this.props.agree != undefined ? '27%': '0'}}>
            The material/information published on this website are the intellectual property of Anu Varghese.
        </p>
      </div>
    )
  }
}
