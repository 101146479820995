import React, { Component } from 'react'
import lawyerLogo from './lawyer-logo.png'
import './header.css'
import { Link } from 'react-router-dom'
import jquery from 'jquery';

export default class HeaderComponent extends Component {

  constructor(props)  {
    super(props);
  }

  render() {
    return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark py-3">
        <img src={lawyerLogo} className="lawyer-logo" alt="lawyer-logo"/>
        <Link className="navbar-brand index"  to="/">Anu Varghese</Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText" data-toggle="collapse" >
          <ul className="navbar-nav">
            <li className={"nav-item " + (this.props.active === "home" ? "active" : "")}>
            <Link className="nav-link header-options" to="/">HOME</Link>
            </li>
            <li className={"nav-item " + (this.props.active === "about" ? "active" : "")}>
            <Link className="nav-link header-options" to="/about">ABOUT</Link>
            </li>
            {/* <li className={"nav-item " + (this.props.active === "testimonials" ? "active" : "")}>
            <Link className="nav-link header-options" to="/testimonials">TESTIMONIALS</Link>
            </li> */}
            <li className={"nav-item " + (this.props.active === "contact" ? "active" : "")}>
            <Link className="nav-link header-options" to="/contact">CONTACT</Link>
            </li>
            <li className={"nav-item " + (this.props.active === "terms" ? "active" : "")}>
            <Link className="nav-link header-options" to="/terms">TERMS</Link>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}
