import React, { Component } from 'react'
import './contact.css'
import contactImage from './contact.png'

export default class ContactComponent extends Component {

  constructor(props)  {
    super(props)
  }

  render() {
    return (
    <div className="container" style={{marginTop: this.props.marginTop}}>
      <div className="row"> 
        <div className="col-md-7 text-center" style={{padding: '4%'}}>
            <div className="call-text">Online <br/> Consultation</div>
            <a href="tel:+91-8698478132" className="btn btn-lg btn-dark call-button"><svg style={{marginRight: '9x'}} width="29" height="32" viewBox="0 0 29 32"  xmlns="http://www.w3.org/2000/svg">
            <path  d="M14.0352 16.7872C19.928 23.4338 21.2649 15.7444 25.0169 19.9746C28.6341 24.0545 30.7131 24.8719 26.1301 30.0411C25.5561 30.5616 21.9087 36.8237 9.09066 22.366C-3.72898 7.90656 1.81816 3.7873 2.27963 3.13981C6.8737 -2.04369 7.58573 0.315536 11.2029 4.39544C14.955 8.62739 8.14235 10.1406 14.0352 16.7872Z" fill="#F9F8F9"/>
            </svg> Call now</a>
        </div>
         <div className="col-md-4">
                <img src={contactImage} alt="contact-image" className='contact-img'/>
         </div>
      </div>
    </div>
    )
  }
}
