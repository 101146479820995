import logo from './logo.svg';
import {Routes,Route} from "react-router-dom";
import './App.css';
import HeaderComponent from './components/header/HeaderComponent';
import HomeComponent from './components/home/HomeComponent';
import ContactComponent from './components/contact/ContactComponent';
import TestimonialComponent from './components/testimonials/TestimonialComponent';
import TermsComponent from './components/terms/TermsComponent';
import FooterComponent from './components/footer/FooterComponent';
import AboutComponent from './components/about/AboutComponent';
import TermsModalComponent from './components/terms/TermsModalComponent';
import React from 'react';


function App() {
  const [showTerms, setShowTerms] = React.useState(window.sessionStorage.getItem('showTerms') == null && !window.sessionStorage.getItem('showTerms'));
  if(!showTerms)   {
    window.sessionStorage.setItem('showTerms', true);
  }
  return (
    <div>
    <Routes>
      <Route path="/" element={<div><HeaderComponent active="home"/><HomeComponent/> <FooterComponent/></div>} />
      <Route path="contact" element={<div><HeaderComponent active="contact"/><ContactComponent marginTop='10%'/> <FooterComponent fixedBotton='fixed-bottom'/></div>} />
      {/* <Route path="testimonials" element={<div><HeaderComponent active="testimonials"/><TestimonialComponent/> <FooterComponent/></div>} /> */}
      <Route path="terms" element={<div><HeaderComponent active="terms"/><TermsComponent agree='none'/> <FooterComponent fixedBotton='fixed-bottom'/></div>} />
      <Route path="about" element={<div><HeaderComponent active="about"/><AboutComponent /> <FooterComponent fixedBotton='fixed-bottom'/></div>} />
    </Routes>
    <TermsModalComponent show={showTerms} onHide={() => setShowTerms(false)}/>
    </div>
  );
}

export default App;
