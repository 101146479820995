import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap';
import TermsComponent from './TermsComponent'

export default class TermsModalComponent extends Component {
    constructor(props) {
        super(props);
    }

  render() {
    return (
        <Modal{...this.props} backdrop="static" keyboard={false} size="lg" aria-labelledby="contained-modal-title-vcenter"centered>
        <Modal.Body>
          <TermsComponent/>
          <div className="text-center">
          <Button className="button-i-agree" variant="success" onClick={this.props.onHide}>I Agree</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}
