import React, { Component } from 'react'
import ContactDetailsComponent from '../contact-details/ContactDetailsComponent'
import ContactComponent from '../contact/ContactComponent'
import FooterComponent from '../footer/FooterComponent'
import HeaderComponent from '../header/HeaderComponent'
import TestimonialComponent from '../testimonials/TestimonialComponent'
import lawyerPhoto from './anu-varghese-photo.png'
import './home.css'

export default class HomeComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
     <div>
      <div className="container-fluid" style={{backgroundColor: '#FAFAFA'}}>
        <div className="row">
            <div className="col-md-6" style={{padding: '6.5%'}}>
                <div className="text">Your Solution to <br/> Legal Consultancy</div>
                <hr/>
                <div className="name mobile-font"><strong>Anu</strong> Varghese</div>
                <hr/> 
            </div>
            <div className="col-md-6 align-self-center" style={{padding: '3%'}}>
                <img src={lawyerPhoto} className="img-fluid ml-4 d-block" alt="anu-varghese-photo" />
            </div>
        </div>
        <div className="row">
          <div className="col-md-12 bold-text">
                Some count that matters
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 small-text">
                Achievement in the journey depicted in numbers
          </div>
        </div>
        <div className="row count">
          <div className="col-md-4 text-center">
              <data className="bold-text font-popins" value="40+">40+</data>
              <br/>
              <p className='count-text'>Taken business legalities</p>
          </div>
          <div className="col-md-4 text-center vl">
              <data className="bold-text" value="45+">45+</data>
              <br/>
              <p className='count-text'>Clients</p>
          </div>
          <div className="col-md-4 text-center vl">
              <data className="bold-text" value="7+">7+</data>
              <br/>
              <p className='count-text'>Years of Journey</p>
          </div>
        </div>
        <div className="row" style={{marginTop: '5%'}}>
          <div className="col-md-12 bold-text">
                Practice  Areas
          </div>
        </div>
        <div className="row practice-areas practice-areas-list">
          <div className="col-md-4 col-xs-12 list-center">
            <ul>
                <li>Civil Law</li>
                <li>Criminal Law</li>
                <li>Family Law</li>
                <li>Marriage counseling</li>
                <li>Marriage registration</li>
            </ul>
          </div>
          <div className="col-md-4 col-xs-12 list-center">
            <ul>
                <li>DRT</li>
                <li>Consumer</li>
                <li>Arbitration </li>
                <li>Appeals </li>
                <li>Revision</li>
            </ul>
          </div>
          <div className="col-md-4 col-xs-12 list-center">
            <ul>
                <li>Wills</li>
                <li>Conveyance </li>
                <li>Deed registration </li>
                <li>Writ Petition</li>
            </ul>
          </div>
        </div>
        <ContactComponent/>
      </div>
        {/* <TestimonialComponent/> */}
        <ContactDetailsComponent marginTop='0%'/>
      </div>
    )
  }
}
