import React, { Component } from 'react'
import './footer.css'

export default class FooterComponent extends Component {
  constructor(props)  {
    super(props);
  }

  render() {
    let fixed = ''
    if(this.props.fixedBotton) {
      fixed = this.props.fixedBotton;
    }
    return (
        <footer className={'footer '+ fixed +' pl-5 pt-3'}>
            © 2022 Anu Varghese. All rights reserved
            <div className='float-end pr-5 pt-1' style={{fontSize: '11px'}}>Design & Developed - <a href='https://www.linkedin.com/in/neil-kawde/' target='_blank' style={{color: '#000000'}}><b>Neil </b></a> and <a href='https://www.linkedin.com/in/rijil-saji-daniel' target='_blank' style={{color: '#000000'}}><b>Rijil</b></a></div>
        </footer>
    )
  }
}
