import React, { Component } from 'react'
import lawyerPhoto from './../home/anu-varghese-photo.png'
import './about.css'

export default class AboutComponent extends Component {

  constructor(props)  {
    super(props);
    this.state  = {
      lawyerImage: lawyerPhoto
    }
  }

  getPracticePeriod(startDate) {
    return (new Date()).getFullYear() - startDate;
  }


  render() {
    return (
        <div className='container-fluid mt-4' style={{backgroundColor: '#FAFAFA'}}>
            <div className='row'>
                <div className='col-md-6 col-xs-6 align-self-center'>
                    <p className="about-content">
                        <strong>Adv. Anu Varghese </strong> has completed her law degree from Pune university and is practicing at Pune and Mumbai Courts for the last {this.getPracticePeriod(2015)} years. She has handled various cases in Civil and Criminal fields. She is a hardworking and dedicated Advocate. Professionalism, effectiveness and complexity of solutions for the clients is the principles followed by her. 
                    </p>
                </div>
                <div className='col-md-6 col-xs-6'>
                    <img src={this.state.lawyerImage} className="lawyer-photo img-fluid ml-4 d-block" alt="anu-varghese-photo"/>
                </div>
            </div>
      </div>
    )
  }
}
