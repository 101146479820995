import React, { Component } from 'react'
import './contact-details.css'
import mailIcon from './mail.png'
import locationIcon from './location.png'
import phoneIcon from './phone.png'

export default class ContactDetailsComponent extends Component {
  render() {
    return (
        <div className='container-fluid' style={{backgroundColor: '#FAFAFA'}}>
            <div className="row">
            <div className="col-md-4 contact-box text-center border-right-line align-self-center">
            <a className="contact-details-font" href="mailto:anuvarghese.av@gmail.com"><img src={mailIcon} alt="mail-logo" style={{marginRight: '1%'}}/> anuvarghese.av@gmail.com</a>
            </div>
            <div className="col-md-4 contact-box text-center align-self-center">
                <img src={locationIcon} alt="location-logo" width="42px" height="41px"/><br/>   
                <data id="address" value="Office No.201, Nirankar Apartments, Above Corn Club, FC Road, Pune-411005">Office No.201, Nirankar Apartments, Above Corn Club, FC Road, Pune-411005 </data> <br/>
                <a href='https://goo.gl/maps/Zj8yhAC57Thw2XBVA' className="contact-details-font" id="location-direction" target='_blank'>GET DIRECTIONS</a>
            </div>
            <div className="col-md-4 contact-box text-center border-left-line align-self-center">
                <data id="phone"  value="+91 86984 78132"><img src={phoneIcon} alt="phone-icon" /> +91 86984 78132</data>
            </div>
        </div>
      </div>
    )
  }
}
